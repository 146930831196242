.sticky-element {
  position: fixed;
  top: 100px;
  z-index: 99;
  right: auto;
  left: auto;
}
.clearfix:before, .clearfix:after {
  display: table;
  content: " ";
}
.clearfix:after {
  clear: both;
}

.center-block {
  display: block;
  margin-right: auto;
  margin-left: auto;
}

.pull-right {
  float: right !important;
}

.pull-left {
  float: left !important;
}

.hide {
  display: none !important;
}

.show {
  display: block !important;
}

.invisible {
  visibility: hidden;
}

.text-hide {
  font: 0/0 a;
  color: transparent;
  text-shadow: none;
  background-color: transparent;
  border: 0;
}

.hidden {
  display: none !important;
}

.affix {
  position: fixed;
}

.row {
  margin-left: -15px;
  margin-right: -15px;
  display: flex;
  flex-flow: row wrap;
}
.row:before, .row:after {
  display: table;
  content: " ";
}
.row:after {
  clear: both;
}

.col-xxl-12, .col-xxl-11, .col-xxl-10, .col-xxl-9, .col-xxl-8, .col-xxl-7, .col-xxl-6, .col-xxl-5, .col-xxl-4, .col-xxl-3, .col-xxl-2, .col-xxl-1, .col-xl-12, .col-xl-11, .col-xl-10, .col-xl-9, .col-xl-8, .col-xl-7, .col-xl-6, .col-xl-5, .col-xl-4, .col-xl-3, .col-xl-2, .col-xl-1, .col-lg-12, .col-lg-11, .col-lg-10, .col-lg-9, .col-lg-8, .col-lg-7, .col-lg-6, .col-lg-5, .col-lg-4, .col-lg-3, .col-lg-2, .col-lg-1, .col-md-12, .col-md-11, .col-md-10, .col-md-9, .col-md-8, .col-md-7, .col-md-6, .col-md-5, .col-md-4, .col-md-3, .col-md-2, .col-md-1, .col-sm-12, .col-sm-11, .col-sm-10, .col-sm-9, .col-sm-8, .col-sm-7, .col-sm-6, .col-sm-5, .col-sm-4, .col-sm-3, .col-sm-2, .col-sm-1, .col-xs-12, .col-xs-11, .col-xs-10, .col-xs-9, .col-xs-8, .col-xs-7, .col-xs-6, .col-xs-5, .col-xs-4, .col-xs-3, .col-xs-2, .col-xs-1 {
  position: relative;
  min-height: 1px;
  padding-left: 15px;
  padding-right: 15px;
}

.col-xs-12, .col-xs-11, .col-xs-10, .col-xs-9, .col-xs-8, .col-xs-7, .col-xs-6, .col-xs-5, .col-xs-4, .col-xs-3, .col-xs-2, .col-xs-1 {
  float: left;
}

.col-xs-1 {
  width: 8.3333333333%;
}

.col-xs-2 {
  width: 16.6666666667%;
}

.col-xs-3 {
  width: 25%;
}

.col-xs-4 {
  width: 33.3333333333%;
}

.col-xs-5 {
  width: 41.6666666667%;
}

.col-xs-6 {
  width: 50%;
}

.col-xs-7 {
  width: 58.3333333333%;
}

.col-xs-8 {
  width: 66.6666666667%;
}

.col-xs-9 {
  width: 75%;
}

.col-xs-10 {
  width: 83.3333333333%;
}

.col-xs-11 {
  width: 91.6666666667%;
}

.col-xs-12 {
  width: 100%;
}

.col-xs-pull-0 {
  right: auto;
}

.col-xs-pull-1 {
  right: 8.3333333333%;
}

.col-xs-pull-2 {
  right: 16.6666666667%;
}

.col-xs-pull-3 {
  right: 25%;
}

.col-xs-pull-4 {
  right: 33.3333333333%;
}

.col-xs-pull-5 {
  right: 41.6666666667%;
}

.col-xs-pull-6 {
  right: 50%;
}

.col-xs-pull-7 {
  right: 58.3333333333%;
}

.col-xs-pull-8 {
  right: 66.6666666667%;
}

.col-xs-pull-9 {
  right: 75%;
}

.col-xs-pull-10 {
  right: 83.3333333333%;
}

.col-xs-pull-11 {
  right: 91.6666666667%;
}

.col-xs-pull-12 {
  right: 100%;
}

.col-xs-push-0 {
  left: auto;
}

.col-xs-push-1 {
  left: 8.3333333333%;
}

.col-xs-push-2 {
  left: 16.6666666667%;
}

.col-xs-push-3 {
  left: 25%;
}

.col-xs-push-4 {
  left: 33.3333333333%;
}

.col-xs-push-5 {
  left: 41.6666666667%;
}

.col-xs-push-6 {
  left: 50%;
}

.col-xs-push-7 {
  left: 58.3333333333%;
}

.col-xs-push-8 {
  left: 66.6666666667%;
}

.col-xs-push-9 {
  left: 75%;
}

.col-xs-push-10 {
  left: 83.3333333333%;
}

.col-xs-push-11 {
  left: 91.6666666667%;
}

.col-xs-push-12 {
  left: 100%;
}

.col-xs-offset-0 {
  margin-left: 0%;
}

.col-xs-offset-1 {
  margin-left: 8.3333333333%;
}

.col-xs-offset-2 {
  margin-left: 16.6666666667%;
}

.col-xs-offset-3 {
  margin-left: 25%;
}

.col-xs-offset-4 {
  margin-left: 33.3333333333%;
}

.col-xs-offset-5 {
  margin-left: 41.6666666667%;
}

.col-xs-offset-6 {
  margin-left: 50%;
}

.col-xs-offset-7 {
  margin-left: 58.3333333333%;
}

.col-xs-offset-8 {
  margin-left: 66.6666666667%;
}

.col-xs-offset-9 {
  margin-left: 75%;
}

.col-xs-offset-10 {
  margin-left: 83.3333333333%;
}

.col-xs-offset-11 {
  margin-left: 91.6666666667%;
}

.col-xs-offset-12 {
  margin-left: 100%;
}

@media (min-width: 544px) {
  .col-sm-12, .col-sm-11, .col-sm-10, .col-sm-9, .col-sm-8, .col-sm-7, .col-sm-6, .col-sm-5, .col-sm-4, .col-sm-3, .col-sm-2, .col-sm-1 {
    float: left;
  }
  .col-sm-1 {
    width: 8.3333333333%;
  }
  .col-sm-2 {
    width: 16.6666666667%;
  }
  .col-sm-3 {
    width: 25%;
  }
  .col-sm-4 {
    width: 33.3333333333%;
  }
  .col-sm-5 {
    width: 41.6666666667%;
  }
  .col-sm-6 {
    width: 50%;
  }
  .col-sm-7 {
    width: 58.3333333333%;
  }
  .col-sm-8 {
    width: 66.6666666667%;
  }
  .col-sm-9 {
    width: 75%;
  }
  .col-sm-10 {
    width: 83.3333333333%;
  }
  .col-sm-11 {
    width: 91.6666666667%;
  }
  .col-sm-12 {
    width: 100%;
  }
  .col-sm-pull-0 {
    right: auto;
  }
  .col-sm-pull-1 {
    right: 8.3333333333%;
  }
  .col-sm-pull-2 {
    right: 16.6666666667%;
  }
  .col-sm-pull-3 {
    right: 25%;
  }
  .col-sm-pull-4 {
    right: 33.3333333333%;
  }
  .col-sm-pull-5 {
    right: 41.6666666667%;
  }
  .col-sm-pull-6 {
    right: 50%;
  }
  .col-sm-pull-7 {
    right: 58.3333333333%;
  }
  .col-sm-pull-8 {
    right: 66.6666666667%;
  }
  .col-sm-pull-9 {
    right: 75%;
  }
  .col-sm-pull-10 {
    right: 83.3333333333%;
  }
  .col-sm-pull-11 {
    right: 91.6666666667%;
  }
  .col-sm-pull-12 {
    right: 100%;
  }
  .col-sm-push-0 {
    left: auto;
  }
  .col-sm-push-1 {
    left: 8.3333333333%;
  }
  .col-sm-push-2 {
    left: 16.6666666667%;
  }
  .col-sm-push-3 {
    left: 25%;
  }
  .col-sm-push-4 {
    left: 33.3333333333%;
  }
  .col-sm-push-5 {
    left: 41.6666666667%;
  }
  .col-sm-push-6 {
    left: 50%;
  }
  .col-sm-push-7 {
    left: 58.3333333333%;
  }
  .col-sm-push-8 {
    left: 66.6666666667%;
  }
  .col-sm-push-9 {
    left: 75%;
  }
  .col-sm-push-10 {
    left: 83.3333333333%;
  }
  .col-sm-push-11 {
    left: 91.6666666667%;
  }
  .col-sm-push-12 {
    left: 100%;
  }
  .col-sm-offset-0 {
    margin-left: 0%;
  }
  .col-sm-offset-1 {
    margin-left: 8.3333333333%;
  }
  .col-sm-offset-2 {
    margin-left: 16.6666666667%;
  }
  .col-sm-offset-3 {
    margin-left: 25%;
  }
  .col-sm-offset-4 {
    margin-left: 33.3333333333%;
  }
  .col-sm-offset-5 {
    margin-left: 41.6666666667%;
  }
  .col-sm-offset-6 {
    margin-left: 50%;
  }
  .col-sm-offset-7 {
    margin-left: 58.3333333333%;
  }
  .col-sm-offset-8 {
    margin-left: 66.6666666667%;
  }
  .col-sm-offset-9 {
    margin-left: 75%;
  }
  .col-sm-offset-10 {
    margin-left: 83.3333333333%;
  }
  .col-sm-offset-11 {
    margin-left: 91.6666666667%;
  }
  .col-sm-offset-12 {
    margin-left: 100%;
  }
}
@media (min-width: 768px) {
  .col-md-12, .col-md-11, .col-md-10, .col-md-9, .col-md-8, .col-md-7, .col-md-6, .col-md-5, .col-md-4, .col-md-3, .col-md-2, .col-md-1 {
    float: left;
  }
  .col-md-1 {
    width: 8.3333333333%;
  }
  .col-md-2 {
    width: 16.6666666667%;
  }
  .col-md-3 {
    width: 25%;
  }
  .col-md-4 {
    width: 33.3333333333%;
  }
  .col-md-5 {
    width: 41.6666666667%;
  }
  .col-md-6 {
    width: 50%;
  }
  .col-md-7 {
    width: 58.3333333333%;
  }
  .col-md-8 {
    width: 66.6666666667%;
  }
  .col-md-9 {
    width: 75%;
  }
  .col-md-10 {
    width: 83.3333333333%;
  }
  .col-md-11 {
    width: 91.6666666667%;
  }
  .col-md-12 {
    width: 100%;
  }
  .col-md-pull-0 {
    right: auto;
  }
  .col-md-pull-1 {
    right: 8.3333333333%;
  }
  .col-md-pull-2 {
    right: 16.6666666667%;
  }
  .col-md-pull-3 {
    right: 25%;
  }
  .col-md-pull-4 {
    right: 33.3333333333%;
  }
  .col-md-pull-5 {
    right: 41.6666666667%;
  }
  .col-md-pull-6 {
    right: 50%;
  }
  .col-md-pull-7 {
    right: 58.3333333333%;
  }
  .col-md-pull-8 {
    right: 66.6666666667%;
  }
  .col-md-pull-9 {
    right: 75%;
  }
  .col-md-pull-10 {
    right: 83.3333333333%;
  }
  .col-md-pull-11 {
    right: 91.6666666667%;
  }
  .col-md-pull-12 {
    right: 100%;
  }
  .col-md-push-0 {
    left: auto;
  }
  .col-md-push-1 {
    left: 8.3333333333%;
  }
  .col-md-push-2 {
    left: 16.6666666667%;
  }
  .col-md-push-3 {
    left: 25%;
  }
  .col-md-push-4 {
    left: 33.3333333333%;
  }
  .col-md-push-5 {
    left: 41.6666666667%;
  }
  .col-md-push-6 {
    left: 50%;
  }
  .col-md-push-7 {
    left: 58.3333333333%;
  }
  .col-md-push-8 {
    left: 66.6666666667%;
  }
  .col-md-push-9 {
    left: 75%;
  }
  .col-md-push-10 {
    left: 83.3333333333%;
  }
  .col-md-push-11 {
    left: 91.6666666667%;
  }
  .col-md-push-12 {
    left: 100%;
  }
  .col-md-offset-0 {
    margin-left: 0%;
  }
  .col-md-offset-1 {
    margin-left: 8.3333333333%;
  }
  .col-md-offset-2 {
    margin-left: 16.6666666667%;
  }
  .col-md-offset-3 {
    margin-left: 25%;
  }
  .col-md-offset-4 {
    margin-left: 33.3333333333%;
  }
  .col-md-offset-5 {
    margin-left: 41.6666666667%;
  }
  .col-md-offset-6 {
    margin-left: 50%;
  }
  .col-md-offset-7 {
    margin-left: 58.3333333333%;
  }
  .col-md-offset-8 {
    margin-left: 66.6666666667%;
  }
  .col-md-offset-9 {
    margin-left: 75%;
  }
  .col-md-offset-10 {
    margin-left: 83.3333333333%;
  }
  .col-md-offset-11 {
    margin-left: 91.6666666667%;
  }
  .col-md-offset-12 {
    margin-left: 100%;
  }
}
@media (min-width: 992px) {
  .col-lg-12, .col-lg-11, .col-lg-10, .col-lg-9, .col-lg-8, .col-lg-7, .col-lg-6, .col-lg-5, .col-lg-4, .col-lg-3, .col-lg-2, .col-lg-1 {
    float: left;
  }
  .col-lg-1 {
    width: 8.3333333333%;
  }
  .col-lg-2 {
    width: 16.6666666667%;
  }
  .col-lg-3 {
    width: 25%;
  }
  .col-lg-4 {
    width: 33.3333333333%;
  }
  .col-lg-5 {
    width: 41.6666666667%;
  }
  .col-lg-6 {
    width: 50%;
  }
  .col-lg-7 {
    width: 58.3333333333%;
  }
  .col-lg-8 {
    width: 66.6666666667%;
  }
  .col-lg-9 {
    width: 75%;
  }
  .col-lg-10 {
    width: 83.3333333333%;
  }
  .col-lg-11 {
    width: 91.6666666667%;
  }
  .col-lg-12 {
    width: 100%;
  }
  .col-lg-pull-0 {
    right: auto;
  }
  .col-lg-pull-1 {
    right: 8.3333333333%;
  }
  .col-lg-pull-2 {
    right: 16.6666666667%;
  }
  .col-lg-pull-3 {
    right: 25%;
  }
  .col-lg-pull-4 {
    right: 33.3333333333%;
  }
  .col-lg-pull-5 {
    right: 41.6666666667%;
  }
  .col-lg-pull-6 {
    right: 50%;
  }
  .col-lg-pull-7 {
    right: 58.3333333333%;
  }
  .col-lg-pull-8 {
    right: 66.6666666667%;
  }
  .col-lg-pull-9 {
    right: 75%;
  }
  .col-lg-pull-10 {
    right: 83.3333333333%;
  }
  .col-lg-pull-11 {
    right: 91.6666666667%;
  }
  .col-lg-pull-12 {
    right: 100%;
  }
  .col-lg-push-0 {
    left: auto;
  }
  .col-lg-push-1 {
    left: 8.3333333333%;
  }
  .col-lg-push-2 {
    left: 16.6666666667%;
  }
  .col-lg-push-3 {
    left: 25%;
  }
  .col-lg-push-4 {
    left: 33.3333333333%;
  }
  .col-lg-push-5 {
    left: 41.6666666667%;
  }
  .col-lg-push-6 {
    left: 50%;
  }
  .col-lg-push-7 {
    left: 58.3333333333%;
  }
  .col-lg-push-8 {
    left: 66.6666666667%;
  }
  .col-lg-push-9 {
    left: 75%;
  }
  .col-lg-push-10 {
    left: 83.3333333333%;
  }
  .col-lg-push-11 {
    left: 91.6666666667%;
  }
  .col-lg-push-12 {
    left: 100%;
  }
  .col-lg-offset-0 {
    margin-left: 0%;
  }
  .col-lg-offset-1 {
    margin-left: 8.3333333333%;
  }
  .col-lg-offset-2 {
    margin-left: 16.6666666667%;
  }
  .col-lg-offset-3 {
    margin-left: 25%;
  }
  .col-lg-offset-4 {
    margin-left: 33.3333333333%;
  }
  .col-lg-offset-5 {
    margin-left: 41.6666666667%;
  }
  .col-lg-offset-6 {
    margin-left: 50%;
  }
  .col-lg-offset-7 {
    margin-left: 58.3333333333%;
  }
  .col-lg-offset-8 {
    margin-left: 66.6666666667%;
  }
  .col-lg-offset-9 {
    margin-left: 75%;
  }
  .col-lg-offset-10 {
    margin-left: 83.3333333333%;
  }
  .col-lg-offset-11 {
    margin-left: 91.6666666667%;
  }
  .col-lg-offset-12 {
    margin-left: 100%;
  }
}
@media (min-width: 1100px) {
  .col-xl-12, .col-xl-11, .col-xl-10, .col-xl-9, .col-xl-8, .col-xl-7, .col-xl-6, .col-xl-5, .col-xl-4, .col-xl-3, .col-xl-2, .col-xl-1 {
    float: left;
  }
  .col-xl-1 {
    width: 8.3333333333%;
  }
  .col-xl-2 {
    width: 16.6666666667%;
  }
  .col-xl-3 {
    width: 25%;
  }
  .col-xl-4 {
    width: 33.3333333333%;
  }
  .col-xl-5 {
    width: 41.6666666667%;
  }
  .col-xl-6 {
    width: 50%;
  }
  .col-xl-7 {
    width: 58.3333333333%;
  }
  .col-xl-8 {
    width: 66.6666666667%;
  }
  .col-xl-9 {
    width: 75%;
  }
  .col-xl-10 {
    width: 83.3333333333%;
  }
  .col-xl-11 {
    width: 91.6666666667%;
  }
  .col-xl-12 {
    width: 100%;
  }
  .col-xl-pull-0 {
    right: auto;
  }
  .col-xl-pull-1 {
    right: 8.3333333333%;
  }
  .col-xl-pull-2 {
    right: 16.6666666667%;
  }
  .col-xl-pull-3 {
    right: 25%;
  }
  .col-xl-pull-4 {
    right: 33.3333333333%;
  }
  .col-xl-pull-5 {
    right: 41.6666666667%;
  }
  .col-xl-pull-6 {
    right: 50%;
  }
  .col-xl-pull-7 {
    right: 58.3333333333%;
  }
  .col-xl-pull-8 {
    right: 66.6666666667%;
  }
  .col-xl-pull-9 {
    right: 75%;
  }
  .col-xl-pull-10 {
    right: 83.3333333333%;
  }
  .col-xl-pull-11 {
    right: 91.6666666667%;
  }
  .col-xl-pull-12 {
    right: 100%;
  }
  .col-xl-push-0 {
    left: auto;
  }
  .col-xl-push-1 {
    left: 8.3333333333%;
  }
  .col-xl-push-2 {
    left: 16.6666666667%;
  }
  .col-xl-push-3 {
    left: 25%;
  }
  .col-xl-push-4 {
    left: 33.3333333333%;
  }
  .col-xl-push-5 {
    left: 41.6666666667%;
  }
  .col-xl-push-6 {
    left: 50%;
  }
  .col-xl-push-7 {
    left: 58.3333333333%;
  }
  .col-xl-push-8 {
    left: 66.6666666667%;
  }
  .col-xl-push-9 {
    left: 75%;
  }
  .col-xl-push-10 {
    left: 83.3333333333%;
  }
  .col-xl-push-11 {
    left: 91.6666666667%;
  }
  .col-xl-push-12 {
    left: 100%;
  }
  .col-xl-offset-0 {
    margin-left: 0%;
  }
  .col-xl-offset-1 {
    margin-left: 8.3333333333%;
  }
  .col-xl-offset-2 {
    margin-left: 16.6666666667%;
  }
  .col-xl-offset-3 {
    margin-left: 25%;
  }
  .col-xl-offset-4 {
    margin-left: 33.3333333333%;
  }
  .col-xl-offset-5 {
    margin-left: 41.6666666667%;
  }
  .col-xl-offset-6 {
    margin-left: 50%;
  }
  .col-xl-offset-7 {
    margin-left: 58.3333333333%;
  }
  .col-xl-offset-8 {
    margin-left: 66.6666666667%;
  }
  .col-xl-offset-9 {
    margin-left: 75%;
  }
  .col-xl-offset-10 {
    margin-left: 83.3333333333%;
  }
  .col-xl-offset-11 {
    margin-left: 91.6666666667%;
  }
  .col-xl-offset-12 {
    margin-left: 100%;
  }
}
@media (min-width: 1420px) {
  .col-xxl-12, .col-xxl-11, .col-xxl-10, .col-xxl-9, .col-xxl-8, .col-xxl-7, .col-xxl-6, .col-xxl-5, .col-xxl-4, .col-xxl-3, .col-xxl-2, .col-xxl-1 {
    float: left;
  }
  .col-xxl-1 {
    width: 8.3333333333%;
  }
  .col-xxl-2 {
    width: 16.6666666667%;
  }
  .col-xxl-3 {
    width: 25%;
  }
  .col-xxl-4 {
    width: 33.3333333333%;
  }
  .col-xxl-5 {
    width: 41.6666666667%;
  }
  .col-xxl-6 {
    width: 50%;
  }
  .col-xxl-7 {
    width: 58.3333333333%;
  }
  .col-xxl-8 {
    width: 66.6666666667%;
  }
  .col-xxl-9 {
    width: 75%;
  }
  .col-xxl-10 {
    width: 83.3333333333%;
  }
  .col-xxl-11 {
    width: 91.6666666667%;
  }
  .col-xxl-12 {
    width: 100%;
  }
  .col-xxl-pull-0 {
    right: auto;
  }
  .col-xxl-pull-1 {
    right: 8.3333333333%;
  }
  .col-xxl-pull-2 {
    right: 16.6666666667%;
  }
  .col-xxl-pull-3 {
    right: 25%;
  }
  .col-xxl-pull-4 {
    right: 33.3333333333%;
  }
  .col-xxl-pull-5 {
    right: 41.6666666667%;
  }
  .col-xxl-pull-6 {
    right: 50%;
  }
  .col-xxl-pull-7 {
    right: 58.3333333333%;
  }
  .col-xxl-pull-8 {
    right: 66.6666666667%;
  }
  .col-xxl-pull-9 {
    right: 75%;
  }
  .col-xxl-pull-10 {
    right: 83.3333333333%;
  }
  .col-xxl-pull-11 {
    right: 91.6666666667%;
  }
  .col-xxl-pull-12 {
    right: 100%;
  }
  .col-xxl-push-0 {
    left: auto;
  }
  .col-xxl-push-1 {
    left: 8.3333333333%;
  }
  .col-xxl-push-2 {
    left: 16.6666666667%;
  }
  .col-xxl-push-3 {
    left: 25%;
  }
  .col-xxl-push-4 {
    left: 33.3333333333%;
  }
  .col-xxl-push-5 {
    left: 41.6666666667%;
  }
  .col-xxl-push-6 {
    left: 50%;
  }
  .col-xxl-push-7 {
    left: 58.3333333333%;
  }
  .col-xxl-push-8 {
    left: 66.6666666667%;
  }
  .col-xxl-push-9 {
    left: 75%;
  }
  .col-xxl-push-10 {
    left: 83.3333333333%;
  }
  .col-xxl-push-11 {
    left: 91.6666666667%;
  }
  .col-xxl-push-12 {
    left: 100%;
  }
  .col-xxl-offset-0 {
    margin-left: 0%;
  }
  .col-xxl-offset-1 {
    margin-left: 8.3333333333%;
  }
  .col-xxl-offset-2 {
    margin-left: 16.6666666667%;
  }
  .col-xxl-offset-3 {
    margin-left: 25%;
  }
  .col-xxl-offset-4 {
    margin-left: 33.3333333333%;
  }
  .col-xxl-offset-5 {
    margin-left: 41.6666666667%;
  }
  .col-xxl-offset-6 {
    margin-left: 50%;
  }
  .col-xxl-offset-7 {
    margin-left: 58.3333333333%;
  }
  .col-xxl-offset-8 {
    margin-left: 66.6666666667%;
  }
  .col-xxl-offset-9 {
    margin-left: 75%;
  }
  .col-xxl-offset-10 {
    margin-left: 83.3333333333%;
  }
  .col-xxl-offset-11 {
    margin-left: 91.6666666667%;
  }
  .col-xxl-offset-12 {
    margin-left: 100%;
  }
}
.page {
  margin-left: auto;
  margin-right: auto;
  max-width: 1170px;
  padding: 5px 18px 35px;
}
@media (min-width: 992px) {
  .page {
    padding-left: 35px;
    padding-right: 35px;
  }
}
.page--has-full-width-header-image .page-header,
.page--has-full-width-header-image .navigation-bar-wrapper,
.page--has-full-width-header-image .region-header-slide-full, .page--has-full-width-header-image:not([style*=background-image]).page-footer {
  position: relative;
  width: 100vw;
  left: 50%;
  margin-left: -50vw;
}
.page--has-full-width-header-image .page-header > .inner,
.page--has-full-width-header-image .navigation-bar-wrapper > .inner,
.page--has-full-width-header-image .region-header-slide-full > .inner, .page--has-full-width-header-image:not([style*=background-image]).page-footer > .inner {
  margin-left: auto;
  margin-right: auto;
  max-width: 1170px;
  padding-left: 35px;
  padding-right: 35px;
}

.page-columns-outer {
  padding-top: 15px;
}
@media (min-width: 992px) {
  .page-columns-outer {
    padding-top: 30px;
  }
}

.page-node-edit .page,
.page-node-add .page {
  max-width: 1400px;
}

.content-column {
  padding-bottom: 30px;
  max-width: 100%;
}

.full-width-section {
  width: auto;
  background-color: #fff;
}

.full-width-section__inner {
  max-width: 1100px;
  margin-left: auto;
  margin-right: auto;
  padding-left: 35px;
  padding-right: 35px;
  background-color: #fff;
}

.full-width-image-section {
  max-width: 2200px;
  margin-left: auto;
  margin-right: auto;
}
.full-width-image-section img {
  max-width: 100%;
  width: auto;
}
.full-width-image-section > .inner {
  max-width: 1100px;
  margin-left: auto;
  margin-right: auto;
}

.page > .region-header-slide > .region-inner {
  max-width: none;
  padding: 0;
}

.views-responsive-grid .views-column {
  margin-bottom: 16px;
}

.row.clearfix,
.region-inner.clearfix {
  overflow: visible;
}

.item-list[class*=layout-] > h3 {
  margin-left: 30px;
}

.float-left,
.float-right,
.media-element[style*=float] {
  float: none !important;
  text-align: center;
  display: block;
  margin: 0 auto 30px !important;
}

.clear {
  clear: both;
}

.clear-left {
  clear: left;
}

.clear-right {
  clear: right;
}

.hide {
  display: none !important;
}

.show {
  display: block !important;
}

@-ms-viewport {
  width: device-width;
}
.visible-xs {
  display: none !important;
}

.visible-sm {
  display: none !important;
}

.visible-md {
  display: none !important;
}

.visible-lg {
  display: none !important;
}

.visible-xlg {
  display: none !important;
}

.visible-xxlg {
  display: none !important;
}

.visible-xs-block,
.visible-xs-inline,
.visible-xs-inline-block,
.visible-sm-block,
.visible-sm-inline,
.visible-sm-inline-block,
.visible-md-block,
.visible-md-inline,
.visible-md-inline-block,
.visible-lg-block,
.visible-lg-inline,
.visible-lg-inline-block,
.visible-xlg-block,
.visible-xlg-inline,
.visible-xlg-inline-block,
.visible-xxlg-block,
.visible-xxlg-inline,
.visible-xxlg-inline-block {
  display: none !important;
}

@media (max-width: 767px) {
  .visible-xs {
    display: block !important;
  }
  table.visible-xs {
    display: table !important;
  }
  tr.visible-xs {
    display: table-row !important;
  }
  th.visible-xs,
  td.visible-xs {
    display: table-cell !important;
  }
}
@media (max-width: 767px) {
  .visible-xs-block {
    display: block !important;
  }
}

@media (max-width: 767px) {
  .visible-xs-inline {
    display: inline !important;
  }
}

@media (max-width: 767px) {
  .visible-xs-inline-block {
    display: inline-block !important;
  }
}

@media (min-width: 544px) and (max-width: 767px) {
  .visible-sm {
    display: block !important;
  }
  table.visible-sm {
    display: table !important;
  }
  tr.visible-sm {
    display: table-row !important;
  }
  th.visible-sm,
  td.visible-sm {
    display: table-cell !important;
  }
}
@media (min-width: 544px) and (max-width: 767px) {
  .visible-sm-block {
    display: block !important;
  }
}

@media (min-width: 544px) and (max-width: 767px) {
  .visible-sm-inline {
    display: inline !important;
  }
}

@media (min-width: 544px) and (max-width: 767px) {
  .visible-sm-inline-block {
    display: inline-block !important;
  }
}

@media (min-width: 768px) and (max-width: 991px) {
  .visible-md {
    display: block !important;
  }
  table.visible-md {
    display: table !important;
  }
  tr.visible-md {
    display: table-row !important;
  }
  th.visible-md,
  td.visible-md {
    display: table-cell !important;
  }
}
@media (min-width: 768px) and (max-width: 991px) {
  .visible-md-block {
    display: block !important;
  }
}

@media (min-width: 768px) and (max-width: 991px) {
  .visible-md-inline {
    display: inline !important;
  }
}

@media (min-width: 768px) and (max-width: 991px) {
  .visible-md-inline-block {
    display: inline-block !important;
  }
}

@media (min-width: 992px) {
  .visible-lg {
    display: block !important;
  }
  table.visible-lg {
    display: table !important;
  }
  tr.visible-lg {
    display: table-row !important;
  }
  th.visible-lg,
  td.visible-lg {
    display: table-cell !important;
  }
}
@media (min-width: 992px) {
  .visible-lg-block {
    display: block !important;
  }
}

@media (min-width: 992px) {
  .visible-lg-inline {
    display: inline !important;
  }
}

@media (min-width: 992px) {
  .visible-lg-inline-block {
    display: inline-block !important;
  }
}

@media (min-width: 1100px) {
  .visible-xlg {
    display: block !important;
  }
  table.visible-xlg {
    display: table !important;
  }
  tr.visible-xlg {
    display: table-row !important;
  }
  th.visible-xlg,
  td.visible-xlg {
    display: table-cell !important;
  }
}
@media (min-width: 1100px) {
  .visible-xlg-block {
    display: block !important;
  }
}

@media (min-width: 1100px) {
  .visible-xlg-inline {
    display: inline !important;
  }
}

@media (min-width: 1100px) {
  .visible-xlg-inline-block {
    display: inline-block !important;
  }
}

@media (min-width: 1420px) {
  .visible-xxlg {
    display: block !important;
  }
  table.visible-xxlg {
    display: table !important;
  }
  tr.visible-xxlg {
    display: table-row !important;
  }
  th.visible-xxlg,
  td.visible-xxlg {
    display: table-cell !important;
  }
}
@media (min-width: 1420px) {
  .visible-xxlg-block {
    display: block !important;
  }
}

@media (min-width: 1420px) {
  .visible-xxlg-inline {
    display: inline !important;
  }
}

@media (min-width: 1420px) {
  .visible-xxlg-inline-block {
    display: inline-block !important;
  }
}

@media (max-width: 767px) {
  .hidden-xs {
    display: none !important;
  }
}
@media (min-width: 544px) and (max-width: 767px) {
  .hidden-sm {
    display: none !important;
  }
}
@media (min-width: 768px) and (max-width: 991px) {
  .hidden-md {
    display: none !important;
  }
}
@media (min-width: 992px) and (max-width: 1099px) {
  .hidden-lg {
    display: none !important;
  }
}
@media (min-width: 1100px) and (max-width: 1419px) {
  .hidden-xlg {
    display: none !important;
  }
}
@media (min-width: 1420px) {
  .hidden-xxlg {
    display: none !important;
  }
}
.visible-print {
  display: none !important;
}

@media print {
  .visible-print {
    display: block !important;
  }
  table.visible-print {
    display: table !important;
  }
  tr.visible-print {
    display: table-row !important;
  }
  th.visible-print,
  td.visible-print {
    display: table-cell !important;
  }
}
.visible-print-block {
  display: none !important;
}
@media print {
  .visible-print-block {
    display: block !important;
  }
}

.visible-print-inline {
  display: none !important;
}
@media print {
  .visible-print-inline {
    display: inline !important;
  }
}

.visible-print-inline-block {
  display: none !important;
}
@media print {
  .visible-print-inline-block {
    display: inline-block !important;
  }
}

@media print {
  .hidden-print {
    display: none !important;
  }
}
.col-left {
  box-sizing: border-box;
  float: left;
  width: 50%;
  padding-right: 15px;
}

.col-right {
  box-sizing: border-box;
  float: left;
  width: 50%;
  padding-left: 15px;
}

.float-left,
.floatleft,
.left,
.media-element[style*=float][style*="left;"] {
  text-align: center;
  display: block;
  margin: 0 auto 30px;
}
.float-left img,
.floatleft img,
.left img,
.media-element[style*=float][style*="left;"] img {
  float: none !important;
}
@media only screen and (min-width:768px) {
  .float-left,
  .floatleft,
  .left,
  .media-element[style*=float][style*="left;"] {
    display: block;
    float: left !important;
    margin: 5px 30px 30px 0 !important;
  }
}

.float-right,
.floatright,
.right,
.media-element[style*=float][style*="right;"] {
  text-align: center;
  display: block;
  margin: 0 auto 30px;
}
.float-right img,
.floatright img,
.right img,
.media-element[style*=float][style*="right;"] img {
  float: none !important;
}
@media only screen and (min-width:768px) {
  .float-right,
  .floatright,
  .right,
  .media-element[style*=float][style*="right;"] {
    display: block;
    float: right !important;
    margin: 5px 0 30px 30px !important;
  }
}

@media only screen and (min-width:768px) {
  .region-two-cols-embed-embed {
    float: right;
    margin: 0 0 30px 30px;
    max-width: 351px;
  }
}