
// _base.scss imports _custom.scss (variables and mixins) and other partials.
// This file defines Grid & Layout for small devices eg. Smartphones (mobile first).
@import "base";

// =============================================================================
//  Sticky Elements
// =============================================================================
.sticky-element{
  position:fixed;
  top:100px;
  z-index:99;
  right:auto;
  left:auto;
  &--left{
    // left:0;
  }
  &--right{
    // right:0;
  }
}

// =============================================================================
//  Grid (BS based)
// =============================================================================

@import 'libraries/bootstrap-sass/assets/stylesheets/bootstrap/utilities';

.row{
  @include make-row();
}

@include make-grid-columns();

// =============================================================================
//  Default template containers
// =============================================================================

.page{
  @if($full-width-page == false){
    margin-left:auto;
    margin-right:auto;
    max-width:$page-max-width + ($page-outer-grid * 2);
    padding:5px ceil($page-outer-grid / 2) $page-outer-grid;
    @include media-breakpoint-up(lg){
      padding-left: $page-outer-grid;
      padding-right: $page-outer-grid;
    }
  }
  // Full width header image > let the whole header 100% VW in this case
  &--has-full-width-header-image{
    .page-header,
    .navigation-bar-wrapper,
    .region-header-slide-full,
    &:not([style*="background-image"]).page-footer{
      position: relative;
      width: 100vw;
      left: 50%;
      margin-left: -50vw;
      > .inner{
        margin-left:auto;
        margin-right:auto;
        max-width: $page-max-width + ($page-outer-grid * 2);
        padding-left: $page-outer-grid;
        padding-right: $page-outer-grid;
      }
    }
  }
}
.page-columns-outer{
  padding-top:$space-thin-v;
  @include media-breakpoint-up(lg){
    padding-top:$space-medium-v;
  }
}

// More Max-width for node edit pages
.page-node-edit,
.page-node-add{
  .page{
    @if($full-width-page == false){
      max-width:1400px;
    }
  }
}

// Tile overview pages have a higher max-width
.front{
  .page,
  .page-header > .inner{
    // currently not in use
    // max-width:$page_max_width_tile_pages;
  }
}

.content-column{
  padding-bottom:$grid-vertical;
  max-width:100%; // overflow Bugfix (eg.: Scrollable Tabs - not the tab container itself is getting scrollable, the whole page will be scrollable)
}

// Footer
// Better use mini-panels to group those blocks.
@if $footer-mini-panel != true{
  @if $show-footer-menu-vertical == true{
    .region-footer > .region-inner{
      @include media-breakpoint-up(md){
        @include make-row();
        // PE: Flexbox same height
        @include display(flex);
      }
      .block{
        @include media-breakpoint-up(md){
          @include make-col();
          @include make-col-span(4);
        }
      }
    }
  }
}


// =============================================================================
//  Full width definitions
// =============================================================================

// Full width sections
.full-width-section{
  width:auto; // we need an attribute to use extend..
  background-color:$full-width-bg-color;
}
.full-width-section__inner{
  max-width:$page-max-width;
  margin-left:auto;
  margin-right:auto;
  padding-left:$page-outer-grid;
  padding-right:$page-outer-grid;

  background-color:$page-background;
}

.full-width-image-section{
  // Only used for detail view of articles
  // Put the image direclty inside this wrapper!
  max-width:$page-max-width * 2; // we dont want this infinite width
  margin-left:auto;
  margin-right:auto;
  img{
    max-width:100%;
    width:auto;
  }
  > .inner{
    // Contents above the image - dont put the image inside this!
    max-width:$page-max-width;
    margin-left:auto;
    margin-right:auto;
  }
}

// Define full width Wrappers
@if($full-width-page == true){
  .page > .region,
  .page-header,
  .page-columns-outer,
  .page-footer{
    @extend .full-width-section;
  }
  .breadcrumb,
  .page > .region > .region-inner,
  .navigation-bar-wrapper > .inner,
  .page-header > .inner,
  .page-columns,
  .page-footer > .inner{
    @extend .full-width-section__inner;
  }
}

// Full width regions with full with contonts (no max-width)
.page > .region-header-slide{
  > .region-inner{
    max-width:none;
    padding:0;
  }
}

// =============================================================================
//  Grid
// =============================================================================

// This file defines default Grid & Layouts (for page layout itself, for panels & custom marked up layouts)
// The needed Bootstrap parts are included inside the include.bootstrap.scss - we just include the things we really need
// Normally you don´t have to touch this file. Everything goes by _configuration.scss

// View Grid
.views-responsive-grid{
  .views-column{
    margin-bottom:$space-thin-v + 1; // TODO-Vorlage: Remove the +1 its just a fix for the .card :hover behavoir
  }
}

// Fix Bootstrap .row clearfix behavoir
.row,
.region-inner{
  &.clearfix{
    overflow:visible;
  }
}

// Quickfix: We need to comensate the negative margin on the group title
.item-list[class*="layout-"] > h3{
  margin-left:$grid;
}

// Tile Grid
@if $layout-tile-grid-active == true{
  .tile-grid{
    margin:0 auto;
    max-width:$tile-width;
    padding-top:5px; // Otherwise tile clip at the top will be cut off
    &__item{
      width:$tile-width; // attention! if you change this value, you have to change it in the layout.custom.js too! Percentage width does not work good with masonry.
    }
    @include media-breakpoint-up(md){
      // 2 Tiles in a "row"
      max-width:($tile-width * 2) + $tile-grid-space;
    }
    @include media-breakpoint-up(lg){
      // 3 Tiles in a "row"
      max-width:($tile-width * 3) + ($tile-grid-space * 2);
    }
    @include media-breakpoint-up(xl){
      // 4 Tiles in a "row"
      max-width:($tile-width * 4) + ($tile-grid-space * 3);
    }
    @include media-breakpoint-up(xxl){
      // 5 Tiles in a "row"
      max-width:($tile-width * 5) + ($tile-grid-space * 4);
    }
  }
}


// =============================================================================
//  Some "Layout helper classes"
// =============================================================================

.float-left,
.float-right,
.media-element[style*="float"]{
  float:none !important;
  text-align:center;
  display:block;
  margin:0 auto $grid-vertical !important;
}
.clear{ clear:both; }
.clear-left{ clear:left; }
.clear-right{ clear:right; }

.hide{ display:none !important; }
.show{ display:block !important; }

// Responsive Helpers
@import 'components/responsive-utilities';

// Enough Width (means: Full content width, or the moment when the sidebar is already moved to bottom, so the content is wider again)
@mixin mobile-image{
  text-align:center;
  display:block;
  margin:0 auto $grid-vertical;
  img{
    // Fix floatfilter CK behavoir wich does not remove the inline float style
    float:none !important;
  }
}

// Content
// -- Two Cols
.col-left{ box-sizing: border-box; float:left; width:50%; padding-right:$grid / 2; }
.col-right{ box-sizing: border-box; float:left; width:50%;  padding-left:$grid / 2; }

// Some "Layout helper classes"
// Using body to make it more important then global.layout.css definitions which is implemented later in cause of technical trouble
// UPDATE: Removed body from the selector again. This sucks if you have to override it somewhere else
.float-left,
.floatleft,
.left,
.media-element[style*="float"][style*="left;"]{
  @include mobile-image();
  @media #{$enough-width}{
    display:block;
    float:left !important;
    margin:5px $grid $grid-vertical 0 !important;
  }
}
.float-right,
.floatright,
.right,
.media-element[style*="float"][style*="right;"]{
  @include mobile-image();
  @media #{$enough-width}{
    display:block;
    float:right !important;
    margin:5px 0 $grid-vertical $grid !important;
  }
}


// =============================================================================
//  Drupal specific (default module styles)
// =============================================================================

@media #{$enough-width}{

  // ========== Panels =========
  .region-two-cols-embed-embed{
    // todo: take a look if this layout still exist.. if it does, try to use bootstrap classes instead of this declaration
    float:right;
    margin:0 0 $grid-vertical $grid;
    max-width:351px; // tile width
  }

}
